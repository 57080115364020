/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Font Weights"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for font weights, use the ", _jsx(_components.code, {
        children: "theme.fontWeights"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n  export const theme = {\n    fontSizes: {\n-     hairline: 100,\n+     'extra-light': 100,\n-     thin: 200,\n      light: 300,\n      normal: 400,\n      medium: 500,\n+     semibold: 600,\n      bold: 700,\n-     extrabold: 800,\n+     'extra-bold': 800,\n      black: 900,\n    },\n  }\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If you don't want to customize it, a set of ", _jsx(_components.code, {
        children: "fontWeights"
      }), " is already defined in default theme:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const defaultTheme = {\n  // ...\n  fontWeights: {\n    hairline: '100',\n    thin: '200',\n    light: '300',\n    normal: '400',\n    medium: '500',\n    semibold: '600',\n    bold: '700',\n    extrabold: '800',\n    black: '900',\n  },\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
